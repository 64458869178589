
.clickable-container{
    text-decoration: none;
    color: black;
    
   
}

.experience-buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

@media all and (min-width: 900px) {

.card-container{
    border-radius: 20px;
    border-color: lightgray;
    border-width: 1px;
    background-color: rgba(218, 218, 218, 0.457);
    border-style: solid;
    margin: 10px 10px;
    max-width: 900px;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-top: 40px;
    transition: all 400ms ease;
   
}

.card-container:hover  {
  
    border-color: rgb(216, 216, 216);
    /*background-color: rgba(193, 193, 193, 0.457);*/
    background-color: rgba(187, 204, 198, 0.104);;
    
    
    transition: all 400ms ease;
    
}

.card-container:hover .experience-image  {

    border-radius: 5px;
    margin-left: -6px;
    box-shadow: 7px 7px 3px rgb(191, 191, 191);
    transition: all 400ms ease;
    
}
.image-container{
    width:100%;
    max-height: 800px;
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden;
   
    
}

.experience-image{
    flex-shrink:0;
    -webkit-flex-shrink: 0;
    /*max-width:70%;
    max-height:90%;*/
    max-width:95%;
    max-height:95%;

    border-radius: 5px;
    box-shadow: 5px 5px 1px rgb(173, 173, 173);
    transition: all 400ms ease;
    
}

.experience-name-text{
    font-size: 70px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 0px;
    white-space: nowrap;
}

.experience-location-text{
    margin-top: 0px;
    color: rgb(84, 84, 84);
}
.text-container{
    /*text-align: left;*/
    text-align:justify;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
 
    margin-right: 20px;
}

.img-button{

    opacity: .35;
    margin-right: 10px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.273);
    border-style: solid;
    border-radius: 10px;
    padding: 1px 10px;
    background-color: rgba(228, 228, 228, 0.22);

}

.img-button:hover{
    border-width: 1px;
    border-color: rgba(142, 142, 142, 0.273);
    border-style: solid;
    opacity: .7;
    
    background-color: rgba(42, 42, 42, 0.22);

}
}

@media all and (max-width: 900px) {

    
    .card-container{
        
        border-top: 0px;
       
        margin-top: 50px;
       
        max-width: 500px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        
    }



    .experience-name-text{
        font-size: 50px;
       font-weight: 600;
        margin-bottom: 0px;
         margin-top: 10px;
    }
    .experience-location-text{
        font-size: 20px;
       font-weight: 500;
        margin-bottom: 0px;
    }

    .text-container{
        /*text-align: left;*/
        text-align:justify;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        background-color: rgba(230, 230, 230, 0.738);
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
        border-top-width: 0px;
        border-top-color:  rgba(211, 211, 211, 0);
        border-right-color: lightgrey;
        border-left-color: lightgrey;
        border-bottom-color: lightgrey;
        border-style: solid;
        border-width: 1px;
        
       
    }
    .image-container{
        
        width:100%;
        /*max-height:900px;*/
        display:flex;
        justify-content:center;
        align-items:center;
        overflow:hidden;
        border-top-right-radius: 09px;
        border-top-left-radius: 9px;
        
        

        
    }
    .experience-image{
        flex-shrink:0;
        -webkit-flex-shrink: 0;
        max-width:100%;
        max-height:100%;
        
    }

    .img-button{

        
        margin-right: 40px;
       
        
       
       
    
    }

}