
.title-my-work{
    margin-top: 30px;
    margin-bottom: 20px;
}

.experiences-cards-container{
       
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    

}

/* Caurosel code

@media all and (min-width: 900px) {
    .experiences-cards-container{
       
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;
        
    
    }
}


@media all and (max-width: 900px) {
    .experiences-container{
       
    }
    .experiences-cards-container{
       
    
        display: flex;
      overflow: auto;
      scroll-snap-type: x mandatory;
      
      
    } 
}
*/






