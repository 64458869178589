@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/d83550/00000000000000007735e60a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/d83550/00000000000000007735e60a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/d83550/00000000000000007735e60a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
}
    

    

@media all and (min-width: 900px) {

    .nav-container{
        display: flex;
        justify-content: space-between;
        max-width:100%;
        padding: 10px;
    
    }
    .logo{
       
        display:block;
        padding: 30px 20px;
        white-space: nowrap;
    
        order: 0;
        font-size: 30px;
        font-weight: 750;
      
    }

    .menu {
        display: flex;
        justify-content: right;
        flex-direction: row;
        flex-wrap: nowrap;
        background: none;
        
    }
    
    .item {
       
        padding: 30px;
        order: 1;
        position: relative;
        display: block; 
        font-size: 25px;
        font-weight: 650;
        color: black;
       
       
    }
    .item:hover{
        color: rgba(56, 116, 96, 0.543); 
        transition: all 100ms ease;
    }
   .menu-link{
    text-decoration: none;

    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border: 1.5px solid black;
    box-shadow: 5px 5px gray;
    
    max-height: 80 px;

   }

   .menu-link:hover{
    box-shadow: 2px 2px rgba(56, 116, 96, 0.543);
    border: 1.5px solid rgba(23, 48, 40, 0.669);
    
    transition: all 200ms ease;
   }


 
  
}

@media all and (max-width: 900px) {


    .nav-container{
       
        justify-content: center;
        max-width:100%;
        
    }
    .menu {
        display: flex;
        padding: 0;
        justify-content: center;
        flex-direction: column;
        /*background: blue;*/
        margin:0;
       
        
    }
    .menu-link{
        text-decoration: none;
       
    }
    .logo{
        display:block;
        padding: 30px;
        order: 0;
        font-size: 35px;
        font-weight: 750;
    }
    
    .item {
        order: 1;
        position: relative;
        display: block; 
        font-size: 23px;
        font-weight: 650;
        color: black;
        min-height: 30px;
     
        background-color: rgba(88, 176, 147, 0.562);
        color: whitesmoke;
        border-color: white;
        border-width: 1px;
        border-style: solid;
        border: .1px solid rgba(54, 157, 123, 0.26);
        box-shadow: 2.5px 2.5px 3px rgba(59, 133, 108, 0.399);
        
        
        border-radius: 13px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 3px;
        margin-top: 3px;
        


       
        
       

       
       
    }
  
}




