
@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/d83550/00000000000000007735e60a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/d83550/00000000000000007735e60a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/d83550/00000000000000007735e60a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
}

.text-greeting-name-emoji{
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 60px;
    animation-name: hand-wave;
  animation-duration: 4.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease
}

@keyframes hand-wave {
    2%, 18% {
        transform: translate3d(-5px, 0, 0);
    }
    4%, 16% {
        transform: translate3d(5px, 0, 0);
    }
  
    6%, 10%, 14% {
        transform: translate3d(-5px, 0, 0);
    }
  
    8%, 12% {
        transform: translate3d(5px, 0, 0);
    }
    
    18.1% {
        transform: translate3d(0px, 0, 0);
    }
    
}
    

    

@media all and (min-width: 900px) {

    .header-container{
        
        display: flex;
        flex-direction: row;
        justify-content: center;
        /*align-items: center;*/
       
        padding: 0px 0px;
        max-width: 100%;
      
       
    }
    .centered-header-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /*align-items: center;*/

        margin-top: 0px;
        max-width: 1000px;
       

    }
    .header-text-container{
        text-align: justify;
        max-width: 700px;
        font-size: 20px;
        padding-left: 50px;
        padding-right: 50px;
      
    }
    .text-greeting-container{
        display: flex;
        flex-direction: row;
        text-align: justify;
        flex-wrap: wrap;
    }
    .text-greeting-name{
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        font-size: 60px;
      

    }
    .text-greeting{
    display: flex;
    flex-direction: row;
    text-align: justify;
    flex-wrap: wrap;
}
    .text-personal-description{
        margin-top: 0px;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 23px;
    }
    .text-personal-history{
        font-size: 20px;
    }
    .text-personal-location{
        font-size: 20px;
    }
    .header-item{
       
    }

   
  

    .img-profile{
        border-radius:50%; 
        width: 300px;
        margin-left: 30px;
       
    }


  
}

@media all and (max-width: 900px) {

    .header-container{
      
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width:100%;
        padding: 20px 20px;
       
    }

    .text-greeting-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
       
    }
    .text-greeting-name{
        font-size: 60px;
        margin-top: 0px;
        margin-bottom: 0px;
        
    }
    .text-personal-description{
        margin-top: 0px;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 23px;
    }
    .text-personal-history{
       
        font-size: 18px;
        text-align:justify
    }
    .text-personal-location{
        font-size: 20px;
        
    }

    .img-profile{
        border-radius:50%; 
       
        
      
        width: 100%;
        max-width: 200px;
        margin: 10px;

    }
}




