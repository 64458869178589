.footer-link{
    color: black;
    
}
.footer-img{
    box-shadow: 3px 3px rgba(146, 146, 146, 0.543);
    border-radius: 6px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
}
.footer-img:hover{
    box-shadow: 1px 1px rgba(34, 34, 34, 0.543);
    margin-right: -2px;
   
    
    transition: all 200ms ease;
}